import React from 'react';
import { useState } from 'react'
import axios from 'axios';
import { Button, Alert } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';


function Mail(values){
    const [email, setMessage] = useState();
    const [sent, setSent] = useState();
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false);

    const emailValidation = () => {
        const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        return !(!email || regex.test(email) === false);
      }
        

    const onEmailChange = e => { 
        setSent(false)
        setMessage(e.target.value)
    }
    const submitEmail = e => {
        e.preventDefault();
        setSending(true)
        const msg = {"email": email, values }
        setSending(true)
        
        axios({
            method: "POST", 
            url:"https://api.simprin.o3creative.fr/send", 
            data:  msg,
        }).then((response)=>{
            setSending(false)
            if (response.data.status === 'success'){
                setSent(true)
                
                resetForm()
            }else if(response.data.status === 'fail'){
                setError(true)
            }
        })
    

    }

  

    const resetForm = (e) => {
        setMessage('')
    }
    return (
        <div className="container mt-2">
            <p className='mt-3'>Pour obtenir votre étiquette par mail, entrez votre adresse email.</p>
            <Form id="contact-form" onSubmit={submitEmail} method="POST">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Adresse Email</Form.Label>
                <Form.Control type="email" placeholder = "Adresse email"   name="email" 
                        required 
                        value={email}
                        onChange= {onEmailChange}
                    />
                </Form.Group>
                { (email && emailValidation()) ?
                <Button type="submit" className="btn btn-dark mt-2 mb-2 submit" >Envoyer l'étiquette par mail</Button>
                :
                null 
                }
                
            </Form>
            {
            sending ?
            <div class="spinner-border text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            : 
            null 
            }
            {
            sent ?
            <Alert  variant="success">
                <p>Votre étiquette a été envoyée avec succès !</p>        
                <hr />
                <div className="d-flex justify-content-end">
                    <Button onClick={() => setSent(false)} variant="outline-success">Fermer</Button>
                </div>
            </Alert>
            : null}
            {
            error ?
            <Alert sent={error} variant="danger">
                <p>Votre étiquette n'a pas été envoyée !</p>        
                <hr />
                <div className="d-flex justify-content-end">
                    <Button onClick={() => setSent(false)} variant="outline-danger">Ré-essayer</Button>
                </div>
            </Alert>
            : null}
        </div>
    )
}

export default Mail