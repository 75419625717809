import React from 'react'
import { useState } from 'react'

function Etiquette({values}){

    return(

        <div id="etiquette" className='bg-dark bg-gradient container px-2 py-2'>
          <h2 className='mt-0'>Votre étiquette</h2>
            <div>
              <div id="num">{values.num }</div>
              <div id="line_container">
                <div id="linea" className={ !values.lineb ? "one_line" : null } >{ values.linea }</div>
                { values.lineb ?<div id="lineb" >{values.lineb }</div> : null }

              </div>
            </div>
        </div>
    )
}
export default Etiquette