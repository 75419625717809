import React from 'react'
import { useState } from 'react'

function Header(){

    return(
        <header className="p-4 bg-dark bg-gradient text-white text-center">
            <h1>Boites aux lettres SIMPRIN</h1>
        </header>
    )
}

export default Header