import React from 'react'
import { useState } from 'react'
import { Form, Button } from 'react-bootstrap'

function Formulaire({  values, setValues, pdf, setPdf }) {

   
    const [image, setImage] = useState({ preview: '', data: '' })
    const [status, setStatus] = useState('')


  
  const handleSubmit = async (e) => {
    e.preventDefault()
    let formData = new FormData()
    formData.append('file', image.data)
    const response = await fetch('http://localhost:5000/image', {
      method: 'POST',
      body: formData,
    })
    if (response) console.log(response)
    if (response) setStatus(response.statusText)
  }


  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
    setImage(img)
    
  }
  const checkPdf = () => {
    if(values.num && values.linea)
    {
      setPdf(true);
    }
    else
    {
      setPdf(false);
    
    }
  }
  const handleChange = e => {
    console.log(e.target.value.toUpperCase())
    setValues(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value.toUpperCase()
    }));

    checkPdf()
    
      }

 
    //console.log(state)
    return (
     <div className='form container mb-3 mw-100'>
      <Form>    
        <Form.Group>
          <Form.Label>
            N° appartement :
            </Form.Label>
            <Form.Control  type="number" name="num" value={values.num} 
            onChange={handleChange} onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            min="1"
            max="42"
            step="1"
            />        
          
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Ligne 1 :
            </Form.Label>
            <Form.Control type="text" name="linea" value={values.linea} onChange={handleChange} maxLength={22} />        
          
          </Form.Group>
          
          <Form.Group>
          <Form.Label>
            Ligne 2 :
            </Form.Label>
            <Form.Control  type="text" name="lineb" value={values.lineb} onChange={handleChange} maxLength={22} />        
          
          </Form.Group>
          </Form>
       

       {/* 
        <hr></hr>
        <form  onSubmit={handleSubmit}>
         <h1>Upload to server</h1>
      {image.preview && <img src={image.preview} width='100' height='100' />}
      <hr></hr>
      
        <input type='file' name='file' onChange={handleFileChange}></input>
        <button type='submit'>Submit</button>
      </form>
      {status && <h4>{status}</h4>}
       */}
      </div>
    )
  }
export default Formulaire
  