import React from "react";
import axios from "axios";
import { Button } from "react-bootstrap"

function Pdf({  values } ){
  
  const onDownload = e => {
    const data = values
    e.preventDefault();
    console.log("DATA", data)
    axios({
      method: "POST", 
      url:"https://api.simprin.o3creative.fr/getPdf", 
      data:  data,
      headers: {'Content-Type': 'application/json','Accept': 'application/pdf'},
      responseType: 'arraybuffer' 
     
    }).then((response)=>{
        if (response.status === 200) {
            var FileSaver = require('file-saver');
            FileSaver.saveAs(new Blob([response.data],{ type: "application/pdf"}),'Etiquette boite aux lettre.pdf');
        }
    })
}
  return(
    <>
      <div>
          <Button className="btn btn-dark" onClick={onDownload}>Télécharger l'étiquette</Button>
      </div>  
    </>
    )
}
export default Pdf