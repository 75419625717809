import React from 'react'
import { useState } from 'react'

import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'

import './App.css'
import Formulaire from './components/Formulaire'
import Header from './components/Header'
import Infos from './components/Infos'
import Etiquette from './components/Etiquette'
import Download from './components/Download'

import {Container, Row, Col, Button } from 'react-bootstrap'

function App() {
 const [values, setValues] = useState({
  num: '',
  linea: '',
  lineb: '',
});
const [pdf, setPdf] = useState(false)


console.log(pdf)
  return (
 <>
 <div  className='App border container-sm px-2 py-2'>
      <Protect boxTitle="Merci de saisir le mot de passe" inputPlaceholder="Mot de passe" buttonLabel="Entrer" sha512='bd61c8af3693feb4a84d86406c6a017b5148cd2177f060c1ec934b4f7532b47f1e2d662c31b5a6c89faaa8a20afd18b4de4c65be291b56fc79d9c646ad823997'>
       
        <Header />
        <Infos />
        <Formulaire values={values} setValues={setValues} pdf={pdf} setPdf={setPdf} />
        <Etiquette values={values} />
        {(values.num && values.linea)        
        ?<Download values={values} />
        : null
        }
        
       </Protect>  
      </div>
   </>     
 
  )
}

export default App
