import React from "react"
function Infos(){

    return(
        <p className='px-2 py-2'>
            Remplissez les champs ci-dessous pour générer l'étiquette de votre boite aux lettres.
        </p>
    )
}

export default Infos