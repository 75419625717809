import React from 'react'
import Pdf from './Pdf'
import Mail from './Mail'

function Download({values}){

    return(
        <div id="download" className='container'>
            <h2 className='mt-2 mb-3'>Obtenir votre étiquette</h2>
            <Pdf values={values}  />
            <Mail values={values}  />
      
        </div>
    )
}

export default Download